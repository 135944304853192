import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable()
export class ApiService {

  constructor(
    private http: HttpClient, private sanitizer: DomSanitizer
  ) { }

  // getQuotesByFilter
  public getQuotesByFilter(data, nv) {
    data.search_params = nv;
    return this.http.post<any>(environment.api + 'get-paged-filtered-quotes', data);
  }

  // getPoliciesByFilter
  public getPoliciesByFilter(data, nv) {
    data.search_params = nv;
    return this.http.post<any>(environment.api + 'get-paged-filtered-customers', data);
  }

  public getDashboardData() {
    return this.http.get(environment.api + 'dashboard');
  }

  public getMembershipCost() {
    return this.http.get(environment.api + 'get-engine-costs');
  }

  public getCancelReasons() {
    return this.http.get(environment.api + 'get-cancel-reasons');
  }

  public setThirdParty(data) {
    return this.http.post<any>(environment.api + 'set-third-party', data);
  }

  public saveRecovery(data) {
    return this.http.post<any>(environment.api + 'save-third-party-recovery', data);
  }

  public saveHorseBox(data) {
    return this.http.post<any>(environment.api + 'save-third-party-horse-box', data);
  }

  public sendQuoteSave(id) {
    return this.http.get(environment.api + 'send-quote-save-email/' + id);
  }

  // get policy by uuid
  public getQuoteByUUID(uuid) {
    return this
      .http
      .get(environment.api + 'get-quote-by-uuid/' + uuid);
  }

  // get policy by uuid
  public getPolicyByUUID(uuid) {
    return this
      .http
      .get(environment.api + 'get-policy-by-uuid/' + uuid);
  }

  // get policy by uuid
  public getMembershipByID(id) {
    return this
      .http
      .get(environment.api + 'get-membership-by-id/' + id);
  }

  // get policy by uuid
  public getVetByID(id) {
    return this
      .http
      .get(environment.api + 'get-vet-by-id/' + id);
  }
  // get policy by uuid
  public getTrailerByID(id) {
    return this
      .http
      .get(environment.api + 'get-trailer-by-id/' + id);
  }
  // get policy by uuid
  public getCustomerByUUID(uuid) {
    return this
      .http
      .get(environment.api + 'get-customer-by-uuid/' + uuid);
  }
  // Save form 2
  public saveAll(data) {
    return this.http.post<any>(environment.api + 'save-full-quote', data);
  }
  // get policy by uuid
  public getClaims(uuid) {
    return this
      .http
      .get(environment.api + 'get-claim-list/' + uuid);
  }
  // get policy by uuid
  public getClaimByID(id) {
    return this
      .http
      .get(environment.api + 'get-claim/' + id);
  }
  // Save claim
  public saveClaim(data) {
    return this.http.post<any>(environment.api + 'save-claim', data);
  }
  // Convert To Policy
  public convertToPolicy(data) {
    return this.http.post<any>(environment.api + 'convert-quote-to-policy', data);
  }
  public getMTACost(data) {
    return this.http.post<any>(environment.api + 'mta-policy-charge', data);
  }
  public onGetHorseChangeCharges(data) {
    return this.http.post<any>(environment.api + 'get-horse-change-charges', data);
  }
  public saveMTA(data) {
    return this.http.post<any>(environment.api + 'mta-policy', data);
  }
  public saveHorseMTA(data) {
    return this.http.post<any>(environment.api + 'update-customer-horses', data);
  }
  public saveFamilyMembers(data) {
    return this.http.post<any>(environment.api + 'update-family-members', data);
  }
  public upgradeVetFee(data) {
    return this.http.post<any>(environment.api + 'upgrade-vet-fee-cover', data);
  }
  public addTrailer(data) {
    return this.http.post<any>(environment.api + 'purchase-new-trailer-cover', data);
  }
  public upgradeTrailer(data) {
    return this.http.post<any>(environment.api + 'upgrade-trailer-cover', data);
  }
  public saveTrailerMTA(data) {
    return this.http.post<any>(environment.api + 'mta-trailer-cover', data);
  }
  public getTrailerMTACharges(data) {
    return this.http.post<any>(environment.api + 'get-trailer-change-charges', data);
  }
  public verifyMigration(data) {
    return this.http.post<any>(environment.api + 'verify-migration', data);
  }
  public upgradeMembership(data) {
    return this.http.post<any>(environment.api + 'upgrade-membership', data);
  }

  public getUpgradeMembershipCost(data) {
    return this.http.post<any>(environment.api + 'get-upgrade-membership-cost', data);
  }

  public saveMembership(data) {
    return this.http.post<any>(environment.api + 'purchase-new-membership', data);
  }
  public saveForm5(formData) {
    return this.http.post<any>(environment.api + 'set-quote-vet-fee-cover-type', formData);
  }
  public cancelMembership(data) {
    return this.http.post<any>(environment.api + 'cancel-membership', data);
  }
  public cancelVetfeeCover(data) {
    return this.http.post<any>(environment.api + 'cancel-vet-fee-cover', data);
  }
  public cancelTrailerCover(data) {
    return this.http.post<any>(environment.api + 'cancel-trailer-cover', data);
  }
  public getCancelDetails(data) {
    return this.http.post<any>(environment.api + 'get-product-cancelation-charges', data);
  }
  public saveFamilyMember(data) {
    return this.http.post<any>(environment.api + 'set-quote-family-member', data);
  }

  public saveHorse(data) {
    return this.http.post<any>(environment.api + 'set-quote-horse', data);
  }

  public deleteHorse(data) {
    return this.http.post<any>(environment.api + 'delete-quote-horse', data);
  }

  public savePolicyHorse(data) {
    return this.http.post<any>(environment.api + 'set-policy-horse', data);
  }

  public saveTerms(data) {
    return this.http.post<any>(environment.api + 'set-quote-terms-and-declarations', data);
  }

  public purchasePolicyWithDD(data) {
    return this.http.post<any>(environment.api + 'purchase_public_policy_direct_debit', data);
  }
  // Get Users
  public getUsersByDivision(id) {
    return this.http.get(environment.api + 'get-user-list-by-division/' + id);
  }
  public updateUser(data) {
    return this.http.post<any>(environment.api + 'save-hh-user', data);
  }
  public updatePassword(data) {
    return this.http.post<any>(environment.api + 'change_password', data);
  }
  // Save division
  public saveDivision(data) {
    return this.http.post<any>(environment.api + 'save-division', data);
  }
  public getDivisions() {
    return this.http.get(environment.api + 'get-division-list');
  }
  public getDivisionById(id) {
    return this.http.get(environment.api + 'get-division/' + id);
  }

  // Save Email
  public saveEmail(data) {
    return this.http.post<any>(environment.api + 'save-mail', data);
  }
  // Resend welcome Email
  public resendWelcomeMails(data) {
    return this.http.post<any>(environment.api + 'send-all-welcome-mails', data);
  }
  public getEmailById(id) {
    return this.http.get(environment.api + 'get-mail/' + id);
  }
  // Save Note
  public saveQuoteNote(data) {
    return this.http.post<any>(environment.api + 'quote-notes/save', data);
  }
  // get Notes by uuid
  public getNotesByQuoteId(id) {
    return this.http.get(environment.api + 'quote-notes/list/' + id);
  }

  // delete Note
  public deleteQuoteNote(data) {
    return this.http.post<any>(environment.api + 'quote-notes/delete', data);
  }

  // Save Note
  public saveNote(data) {
    return this.http.post<any>(environment.api + 'notes/save', data);
  }
  // delete Note
  public deleteNote(data) {
    return this.http.post<any>(environment.api + 'notes/delete', data);
  }

  public getTemplateByCode(code) {
    return this.http.get(environment.api + 'get-template/' + code);
  }
  // Get tags
  public getTags() {
    return this.http.get(environment.api + 'get-replacable-tags');
  }
  // Get Templates
  public getTemplates() {
    return this.http.get(environment.api + 'get-template-list');
  }
  // Get monthly reports
  public getMRReports() {
    return this.http.get(environment.api + 'get-monthly-bdx');
  }
  // Get monthly debtors
  public getDebtorReports() {
    return this.http.get(environment.api + 'get-monthly-debtors');
  }
  // Save Template
  public saveTemplate(data) {
    return this.http.post<any>(environment.api + 'save-template', data);
  }
  // Send password
  public sendPassword(data) {
    return this.http.post<any>(environment.api + 'send-password-reset-mail', data);
  }
  // save Followed Up
  public saveFollowedUp(data) {
    return this.http.post<any>(environment.api + 'set-followed-up', data);
  }

  // Save Document
  public saveDocument(data) {
    return this.http.post<any>(environment.api + 'documents/save', data);
  }
  // Delete Document
  public deleteDocument(data) {
    return this.http.post<any>(environment.api + 'documents/delete', data);
  }

  // get Documents by uuid
  public getDocumentContent(id) {
    return this.http.get<Blob>(environment.api + 'documents/download/' + id, {
      responseType: 'blob' as 'json',
    });
  }
  // get Report by type
  public getReportContent(data) {
    return this.http.post<Blob>(environment.api + 'reports', data, {
      params: data,
      responseType: 'blob' as 'json',
    });
  }
  public getBDXReportContent(id) {
    return this.http.get<Blob>(environment.api + 'bdx-document/' + id, {
      responseType: 'blob' as 'json',
    });
  }
  public getDebtorReportContent(id) {
    return this.http.get<Blob>(environment.api + 'report-document/' + id, {
      responseType: 'blob' as 'json',
    });
  }
  public reSubmitDDPayment(data) {
    return this.http.post<any>(environment.api + 're-submit-dd-payment', data);
  }
  public updateAutoRenew(data) {
    return this.http.post<any>(environment.api + 'set-auto-renew', data);
  }
  public updatePaymentHoliday(data) {
    return this.http.post<any>(environment.api + 'set-payment-holiday', data);
  }
  public updateMarketingPrefrence(data) {
    return this.http.post<any>(environment.api + 'set-marketing-preference', data);
  }
  public updateUserEmail(data) {
    return this.http.post<any>(environment.api + 'change-customer-email', data);
  }
  public updatePlanOnRenew(data) {
    return this.http.post<any>(environment.api + 'set-auto-renew-plan', data);
  }
  public updateDD(data) {
    return this.http.post<any>(environment.api + 'update-direct-debit-customer', data);
  }

  public activatecontract(data) {
    return this.http.post<any>(environment.api + 'activate-direct-debit-contract', data);
  }

  public updateDDToSwitch(data) {
    return this.http.post<any>(environment.api + 'shift-product-to-direct-debit', data);
  }
  public askSwitchDirectDebit(data) {
    return this.http.post<any>(environment.api + 'direct-debit-shift-actioned', data);
  }
  
  // Save claim
  public takePayment(data) {
    return this.http.post<any>(environment.api + 'take-payment', data);
  }
  public refundPayment(data) {
    return this.http.post<any>(environment.api + 'refund-payment', data);
  }
  public generateDocument(url) {
    return this.http.get(environment.api + url);
  }

  public download(content, filename, contentType) {
    if (!contentType) {
      contentType = 'application/octet-stream';
    }
    const a = document.createElement('a');
    const blob = new Blob([content], { 'type': contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }

  public getSanitizerUrl(quoteId, source) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(environment.host + 'payment-form/' + quoteId + '/' + source);
  }

  // External urls
  // Get address by postcode
  public getAddress(postcode) {
    return this
      .http
      .get('https://api.getAddress.io/find/' + postcode + '?api-key=' + environment.getAddApiKey + '&expand=true ');
  }

  getUserType() {
    const usr = localStorage.getItem('user');
    if (usr) {
      return JSON.parse(usr).role_type;
    } else {
      return 4;
    }
  }
}
