import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: number;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '', role: 5 },
  { path: '/policies', title: 'Policies', icon: 'ni-bullet-list-67', class: '', role: 5 },
  { path: '/quotes', title: 'Quotes', icon: 'ni ni-money-coins', class: '', role: 3 },
  { path: '/reports', title: 'Reports', icon: 'ni-bullet-list-67', class: '', role: 4 },
  { path: '/divisions', title: 'Divisions', icon: 'fa fa-users', class: '', role: 1 },
  { path: '/templates', title: 'Email Template', icon: 'fa fa-envelope', class: '', role: 1 },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public userType = 4;
  public isCollapsed = true;

  constructor(private router: Router, private apiService: ApiService) { }

  ngOnInit() {
    this.userType = this.apiService.getUserType();
    this.menuItems = ROUTES.filter(menuItem => menuItem.role >= this.userType);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }
}
