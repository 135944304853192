import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { LoaderState } from './loader';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  toasts: any[] = [];
  public isLoading = new BehaviorSubject(false);
  constructor() { }

  showToast(textOrTpl: string | TemplateRef<any>, tclass) {
    const options = {
      classname: 'bg-' + (tclass || 'danger') + ' text-white',
      delay: 7000,
      autohide: true,
      // headertext: 'Toast Header'
    }
    this.toasts.push({ textOrTpl, ...options });
  }

  removeToast(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  getAge(dob, inYear) {
    if (inYear) {
      return moment().diff(dob, 'years');
    } else {
      return moment().diff(dob, 'days');
    }
  }
}
